import React, { useState, useEffect } from 'react'
import { Images } from '../Images/Index';
import { Link } from "react-router-dom";
// import '../Css/Notification.css';
import { useNavigate } from "react-router-dom";
// import Api from "../../src/Components/Services/ApiCall";
import Api from "../Components/Services/ApiCall";
import CSS from "../App.css";
import $ from 'jquery';
import Departmentcreation from './Admin/Departmentcreation';
import Agentcommision from './Admin/Agentcommision';

function Base({ compayprofile, Rolecreationgrid, Departmentcreate, Staffgrade, Officegrade,
  Officecreation, Employeecreate, Agentcreate, Employercreate, Jobseekercreate, Jobseekerplan, Employerplan,
  Brokercommission, Agentcommission, Divisional, Salesbill, Salesbillreturn, Receipt, PaymentExpence, Bankaccount,
  Deposit, Branchperformance, Employeeperformance, Agentbrokerperformance, Digitalsignature,
  EmploeePaymentReport, OnlinePaymentReport, Declarecommission, BoEmployerApproval, BoJobseekerApproval, Master,
  Employent }) {



  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);
  // $('a').click(function(){
  //   $('a.active').each(function(){
  //     $(this).removeClass('active');
  //   });
  //   $(this).addClass('active');
  // });
  //  function navactive(id)
  // {



  // }



  //to show logout button under profile
  const navigate = useNavigate();

  // const navigate = useNavigate();
  const [clickLogoutshow, setclickLogoutshow] = useState(false)
  const onclicklogoutshow = (showlogoutbtn) => {
    setclickLogoutshow(showlogoutbtn)
  }


  const [clickNotificationshow, setclickNotificationshow] = useState(false)
  const onclickNotificationshow = (showNotificationbtn) => {
    setclickNotificationshow(showNotificationbtn)
  }



  const [clickAdminshow, setclickAdminshow] = useState(false)
  const onclickAdminshow = (showAdmindropdown) => {
    setclickAdminshow(showAdmindropdown)
  }
  // to show Transcation dropdown
  const [clickTranscationshow, setclickTranscationshow] = useState(false)
  const onclickTranscationshow = (showTranscationdropdown) => {
    setclickTranscationshow(showTranscationdropdown)
  }
  // to show Staffoffice dropdown
  const [clickStaffofficeshow, setclickStaffofficeshow] = useState(false)
  const onclickStaffofficeshow = (showStaffofficedropdown) => {
    setclickStaffofficeshow(showStaffofficedropdown)
  }
  // to show Staffoffice dropdown
  const [clickPlancreationshow, setclickPlancreationshow] = useState(false)
  const onclickPlancreationshow = (showPlancreationdropdown) => {
    setclickPlancreationshow(showPlancreationdropdown)
  }
  const [clickMastercreationshow, setclickMastercreationshow] = useState(false)
  const onclickMastercreationshow = (clickMastercreationshow) => {
    setclickMastercreationshow(clickMastercreationshow)
  }

  //to show Commision details
  const [clickCommisionshow, setclickCommisionshow] = useState(false)
  const onclickCommisionshow = (showCommisiondropdown) => {
    setclickCommisionshow(showCommisiondropdown)
  }

  //to show Sales details
  const [clickSalesshow, setclickSalesshow] = useState(false)
  const onclickSalesshow = (showSalesdropdown) => {
    setclickSalesshow(showSalesdropdown)
  }


  //to show Services details
  const [clickServicesshow, setclickServicesshow] = useState(false)
  const onclickServicesshow = (showServicesdropdown) => {
    setclickServicesshow(showServicesdropdown)
  }

  //to show General setting details
  const [clickGeneralsettingshow, setclickGeneralsettingshow] = useState(false)
  const onclickGeneralsettingshow = (showGeneralsettingdropdown, value) => {
    setIconchanges(value);
    setclickGeneralsettingshow(showGeneralsettingdropdown)

  }

  // to show registration dropdown
  const [clickRegistrationshow, setclickRegistrationshow] = useState(false)
  const onclickRegistrationshow = (showRegistrationdropdown, value) => {
    setIconchanges(value);
    setclickRegistrationshow(showRegistrationdropdown)
  }
  // to show agent dropdown
  const [clickAgentshow, setclickAgentshow] = useState(false)
  const onclickAgentshow = (showAgentdropdown) => {
    setclickAgentshow(showAgentdropdown)
  }
  // to showReport dropdown
  const [clickReportshow, setclickReportshow] = useState(false)
  const onclickReportshow = (showReportdropdown) => {
    setclickReportshow(showReportdropdown)
  }
  // to show User dropdown
  const [clickUsershow, setclickUsershow] = useState(false)
  const onclickUsershow = (showUserdropdown) => {
    setclickUsershow(showUserdropdown)
  }

  const [clickResumeshow, setclickResumeshow] = useState(false)
  const onclickResumeshow = (showResumedropdown) => {
    setclickResumeshow(showResumedropdown)
  }
  const [clickTranscationsshow, setclickTranscationsshow] = useState(false)
  const onclickTranscationsshow = (showTranscationsdropdown) => {
    setclickTranscationsshow(showTranscationsdropdown)
  }

  // to toggle sidebar
  const [toggleSidebar, settoggleSidebar] = useState(false)
  const onclickToggleshow = (value) => {
    settoggleSidebar(value)
    if (value) {
      $('body').addClass('toggle-sidebar')
    }
    else {
      $('body').removeClass('toggle-sidebar')
    }
  }

  const logout = () => {
    navigate('/')
  }
  // let admin = sessionStorage.getItem("admin")

  const menu = JSON.parse(sessionStorage.getItem("roleaccess"))
  const [username, setusername] = useState("");

  const [show, setShow] = useState("");
  useEffect(() => {

    setusername(sessionStorage.getItem("username"))

  });

  function checkmenu(id) {
    return menu.filter(function (menu) {
      try {

        return menu.MenuID == id;
      }
      catch {

        return menu.MenuID == id;
      }

    });

  }

  const [iconchanges, setIconchanges] = useState(false);




  return (
    <>



      <body class={toggleSidebar ? "toggle-sidebar" : ""} >

        <div id="header" class="header fixed-top d-flex align-items-center">

          <div class="d-flex align-items-center justify-content-between">
            <a class="logo d-flex align-items-center">
              <img src={Images.mainlogo} alt="pelago" className="userlg_img" />
            </a>

            <i class="bi bi-list toggle-sidebar-btn" onClick={() => onclickToggleshow(!toggleSidebar)}></i>




          </div>


          <nav class="header-nav ms-auto" >
            <ul class="d-flex align-items-center">

              {/* <li class="nav-item d-block d-lg-none">
                <a class="nav-link nav-icon search-bar-toggle " >
                  <i class="bi bi-search"></i>
                </a>
              </li> */}
              {/* <!-- End Search Icon--> */}
              {/* 
              <li class="nav-item dropdown">
              
                <a class="nav-link nav-icon"  data-bs-toggle="dropdown" >
                {/* <Link to="/Notification"> */}
              {/* <i class="bi bi-bell"  ></i>
                 
                  <span class="badge bg-primary badge-number">4</span> */}
              {/* </Link> */}
              {/* </a> */}
              {/* <!-- End Notification Icon --> */}
              {/* 
                <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow notifications">
                  <li class="dropdown-header">
                    You have 4 new notifications
                    <a><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
                  </li>
                </ul> */}

              {/* <!-- End Notification Dropdown Items --> */}

              {/* </li> */}
              {/* <!-- End Notification Nav --> */}







              {/* <practice> */}

              <li class="nav-item dropdown pe-3">

                <a class={clickNotificationshow ? "nav-link nav-profile d-flex align-items-center pe-0 show" : "nav-link nav-profile d-flex align-items-center pe-0 "} onClick={(Notification) => onclickNotificationshow(!clickNotificationshow)} data-bs-toggle="dropdown">
                  {/* <img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle" /> */}

                  <li class="dropdown-header">
                    <a class="nav-link nav-icon" data-bs-toggle="dropdown" >

                      <i class="bi bi-bell"  ></i>

                      <span class="badge bg-primary badge-number">0</span>

                    </a>
                  </li>
                  {/* <span class="d-none d-md-block dropdown-toggle ps-2">{username} </span> */}
                </a>
              </li>
              {/* <!-- End Profile Iamge Icon --> */}

              {/* <ul  id="Profiledropdown"class={clickNotificationshow ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile show" : "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"} style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(-16px, 38px)" }}>
  <li class="dropdown-header">


  <li class="dropdown-header">
              You have 4 new notifications
              <a ><span class="badge rounded-pill bg-primary p-2 ms-2">View all</span></a>
            </li>
            
            <li>
              <hr class="dropdown-divider"></hr>
            
              </li>
            <li class="notification-item">
              <i class="bi bi-exclamation-circle text-warning"></i>
              
            <div>
                <h4>Lorem Ipsum</h4>
                <p>Quae dolorem earum veritatis oditseno</p>
                <p>30 min. ago</p>
              </div>
            </li>
            <li>
              <hr class="dropdown-divider"></hr>
            </li>

            <li class="notification-item">
              <i class="bi bi-x-circle text-danger"></i>
              <div>
                <h4>Atque rerum nesciunt</h4>
                <p>Quae dolorem earum veritatis oditseno</p>
                <p>1 hr. ago</p>
              </div>
            </li>

            <li>
              <hr class="dropdown-divider"></hr>

            </li>

            <li class="notification-item">
              <i class="bi bi-check-circle text-success"></i>
              <div>
                <h4>Sit rerum fuga</h4>
                <p>Quae dolorem earum veritatis oditseno</p>
                <p>2 hrs. ago</p>
              </div>
            </li>

            <li>
              <hr class="dropdown-divider"></hr>
            </li>

            <li class="notification-item">
              <i class="bi bi-info-circle text-primary"></i>
              <div>
                <h4>Dicta reprehenderit</h4>
                <p>Quae dolorem earum veritatis oditseno</p>
                <p>4 hrs. ago</p>
              </div>
            </li>

            <li>
              <hr class="dropdown-divider"></hr>
            </li>
            <li class="dropdown-footer">
              <a >Show all notifications</a>
            </li>
    <h6></h6>
    <span>
      {sessionStorage.getItem("Notification")}
    </span>
  </li>
  <li>
    <hr class="dropdown-divider" />
  </li>

  <li> */}
              {/* <Link to='/Notification'> */}
              {/* <a class="badge rounded-pill bg-primary p-2 ms-2" href="Notification" > */}

              {/* <i class="bi bi-person"></i> */}

              {/* <span>My Profile</span> */}

              {/* </a> */}
              {/* </Link> */}
              {/* </li>
  <li>
    <hr class="dropdown-divider" />
  </li> */}


              {/* <li>
    <a class="dropdown-item d-flex align-items-center" >
    </a>
  </li> */}
              {/* </ul> */}
              {/* <!-- End Profile Dropdown Items --> */}
              {/* </li> */}
              {/* <!-- End Profile Nav --> */}
              {/* 
<practice> */}




              {/* <li class="nav-item dropdown">

                <a class="nav-link nav-icon" data-bs-toggle="dropdown">
                  <i class="bi bi-chat-left-text"></i>
                  <span class="badge bg-success badge-number">3</span>
                </a>
                {/* <!-- End Messages Icon --> */}



              {/* </li> */}
              {/* <!-- End Messages Nav --> */}

              <li class="nav-item dropdown pe-3">

                <a class={clickLogoutshow ? "nav-link nav-profile d-flex align-items-center pe-0 show" : "nav-link nav-profile d-flex align-items-center pe-0 "} onClick={() => onclicklogoutshow(!clickLogoutshow)} data-bs-auto-close="outside" data-bs-toggle="dropdown">
                  {/* <img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle" /> */}
                  <i class="bi bi-person-circle"></i>
                  <span class="d-none d-md-block dropdown-toggle ps-2">{username} </span>
                </a>
                {/* <!-- End Profile Iamge Icon --> */}

                <ul id="Profiledropdown" class={clickLogoutshow ? "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile show" : "dropdown-menu dropdown-menu-end dropdown-menu-arrow profile"} style={{ position: "absolute", inset: "0px 0px auto auto", margin: "0px", transform: "translate(-16px, 38px)" }}>
                  <li class="dropdown-header">
                    <h6></h6>
                    <span>
                      {sessionStorage.getItem("roleaUsertypeccess") || "Employer"}
                    </span>

                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>

                  <li>
                    <Link to='/Myprofile' state={{ ID: sessionStorage.getItem("MUserID"), Flag: 'Prof' }}>
                      {/* <Link to='#'> */}
                      <a class="dropdown-item d-flex align-items-center" href="" >

                        <i class="bi bi-person"></i>

                        <span>My Profile</span>

                      </a>
                    </Link>
                  </li>
                  <li>
                    <hr class="dropdown-divider" />
                  </li>


                  <li>
                    <a class="dropdown-item d-flex align-items-center" >
                      <i class="bi bi-box-arrow-right"></i>
                      <span onClick={logout}>Sign Out</span>
                    </a>
                  </li>
                </ul>
                {/* <!-- End Profile Dropdown Items --> */}
              </li>
              {/* <!-- End Profile Nav --> */}

            </ul>
          </nav>
          {/* <!-- End Icons Navigation --> */}

        </div>
        <div id="sidebar" class="sidebar">

          <ul class="sidebar-nav" id="sidebar-nav">
            {Api.CheckView(1) == 1 ?
              <li class="nav-item">
                <a
                  class="nav-link " href='/dash'>
                  <i class="bi bi-grid"></i>
                  <span>Dashboard</span>
                </a>
              </li>
              : null}



            {(Api.CheckView(2) == 1 || Api.CheckView(3) == 1 || Api.CheckView(4) == 1
              || Api.CheckView(5) == 1 || Api.CheckView(6) == 1 || Api.CheckView(7) == 1
              || Api.CheckView(8) == 1 || Api.CheckView(9) == 1) ?
              <li class="nav-item">
                <a class={clickGeneralsettingshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickGeneralsettingshow(!clickGeneralsettingshow)} data-bs-toggle="collapse"  >
                  <i class="bi bi-gear"></i><span>General Setting</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>


                <ul id="components-nav" class={(clickGeneralsettingshow || compayprofile || Rolecreationgrid || Departmentcreate || Staffgrade || Officegrade || Officecreation || Divisional) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    {Api.CheckView(2) == 1 ?
                      <li class={compayprofile ? "list" : ""}>
                        <Link to="/companyprofiletable">
                          <i class="bi bi-circle"></i><span>Profile</span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(3) == 1 ?
                      <li>
                        <Link to="/tax">
                          <i class="bi bi-circle"></i><span>Taxes Setting</span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(4) == 1 ?
                      <li class={Rolecreationgrid ? "list" : ""}>
                        <Link to="/Rolecreationgrid">
                          <i class="bi bi-circle"></i><span>Role</span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(5) == 1 ?
                      <li class={Departmentcreate ? "list" : ""}>
                        <Link to='/Departmentcreationtable'>
                          <i class="bi bi-circle"></i><span>Department  Creation </span>
                        </Link>
                      </li>
                      : null}

                    {Api.CheckView(6) == 1 ?
                      <li class={Staffgrade ? "list" : ""}>
                        <Link to='/staffgradetypetable'>
                          <i class="bi bi-circle"></i><span>Staff Grade</span>
                        </Link>
                      </li>
                      : null}

                    {Api.CheckView(7) == 1 ?
                      <li class={Officegrade ? "list" : ""}>
                        <Link to='/officegradecreationtable'>
                          <i class="bi bi-circle"></i><span>Office Grade </span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(8) == 1 ?
                      <li class={Officecreation ? "list" : ""}>
                        <Link to='/officetypecreationtable'>
                          <i class="bi bi-circle"></i><span>Office Creation  </span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(9) == 1 ?
                      <li class={Divisional ? "list" : ""}>
                        <Link to='/divisionalofficedistlisttable'>
                          <i class="bi bi-circle"></i><span>Divisional Office Dist List  </span>
                        </Link>
                      </li>
                      : null}

                    {sessionStorage.getItem('AccessType') == 'User' ?
                      <li >
                        <Link to='/Email_edit_table'>
                          <i class="bi bi-circle"></i><span>Email Edit Table </span>
                        </Link>
                      </li>
                      : null}

                  </ul>
                </ul>

              </li>
              : null}

            {(Api.CheckView(10) == 1 || Api.CheckView(11) == 1 || Api.CheckView(12) == 1 || Api.CheckView(13) == 1) ?
              <li class="nav-item">
                <a class={clickRegistrationshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickRegistrationshow(!clickRegistrationshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-stickies"></i><span>Registration</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickRegistrationshow || Employeecreate || Agentcreate || Employercreate || Jobseekercreate) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    {Api.CheckView(10) == 1 ?
                      <li class={Employeecreate ? "list" : ""}>
                        <Link to="/empcreationtable">
                          <i class="bi bi-circle"></i><span>Employee Creation</span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(11) == 1 ?
                      <li class={Agentcreate ? "list" : ""}>
                        <Link to="/AgentCreation">
                          <i class="bi bi-circle"></i><span>Agent Creation</span>
                        </Link>
                      </li>
                      : null}
                    {sessionStorage.getItem('AccessType') == 'User' ?
                      <li class="">
                        <Link to="/guest_table">
                          <i class="bi bi-circle"></i><span>Super Admin Creation</span>
                        </Link>
                      </li>
                      : null}

                    {Api.CheckView(12) == 1 ?
                      <li class={Employercreate ? "list" : ""}>
                        <Link to="/EmployerCreation_table">
                          <i class="bi bi-circle"></i><span>Employer Creation</span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(13) == 1 ?
                      <li class={Jobseekercreate ? "list" : ""}>
                        <Link to="/Jobseeker_table">
                          <i class="bi bi-circle"></i><span>JobSeeker Creation</span>
                        </Link>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>
              : null}

            {(Api.CheckView(14) == 1 || Api.CheckView(15) == 1 || Api.CheckView(16) == 1) ?
              <li class="nav-item">
                <a class={clickTranscationshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickTranscationshow(!clickTranscationshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-bank"></i><span>Bank/Cash</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={clickTranscationshow || Receipt || PaymentExpence || Bankaccount || Deposit ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>

                    {/* {Api.CheckView(14) == 1 ?
                      <li class={PaymentExpence ? "list" : ""}>
                        <Link to="/Expenses_table">
                          <i class="bi bi-circle"></i><span>Expenses</span>
                        </Link>
                      </li>
                      : null} */}
                    {Api.CheckView(15) == 1 ?
                      <li class={Bankaccount ? "list" : ""}>
                        <Link to='/bankaccounttable'>
                          <i class="bi bi-circle"></i><span>Bank Account   </span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(16) == 1 ?
                      <li class={Deposit ? "list" : ""}>
                        <Link to='/deposittable'>
                          <i class="bi bi-circle"></i><span>Deposit</span>
                        </Link>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>
              : null}
            {sessionStorage.getItem('AccessType') == 'Agent' ?
              <>
                {JSON.parse(sessionStorage.getItem('userdetails'))[0]['Subscribe'] == "Paid" ?

                  null :
                  <li class="nav-item">
                    <Link class="nav-link " to={'/Subscribeform'}
                      state={{ ID: JSON.parse(sessionStorage.getItem('userdetails'))[0]['Mstuserregid'], Flag: 'S', Code: JSON.parse(sessionStorage.getItem('userdetails'))[0]['Msusertregcode'] }}>
                      <i class="bi bi-grid"></i>
                      <span>Subscribe</span>
                    </Link>
                  </li>
                }
              </>
              : null}

            {sessionStorage.getItem('AccessType') == 'Agent' ?
              <>
                  <li class="nav-item">
                    <Link class="nav-link " to={'/PayoutTable'}
                      state={{ ID: 0, Flag: 'I'}}>
                      <i class="bi bi-grid"></i>
                      <span>Payout Request</span>
                    </Link>
                  </li> 
              </>
              : null}

            {(Api.CheckView(17) == 1 || Api.CheckView(18) == 1) ?
              <li class="nav-item">
                <a class={clickPlancreationshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickPlancreationshow(!clickPlancreationshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-lightbulb"></i><span>Plan Creation</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickPlancreationshow || Jobseekerplan || Employerplan) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    {Api.CheckView(17) == 1 ?
                      <li class={Jobseekerplan ? "list" : ""}>
                        <Link to='/Agentplancreationtable'>
                          <i class="bi bi-circle"></i><span> Agent  Plan </span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(18) == 1 ?
                      <li class={Employerplan ? "list" : ""}>
                        <Link to='/employerplancreationtable'>
                          <i class="bi bi-circle"></i><span> Employer Jobseeker Plan  </span>
                        </Link>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>

              : null}

            {sessionStorage.getItem('AccessType') == 'User' || Api.CheckView(30) == 1 ?
              <li class="nav-item">
                <a class={clickMastercreationshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickMastercreationshow(!clickMastercreationshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-gear"></i><span>Master Creation</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickMastercreationshow || Master || Employent) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    <li class={Master ? "list" : ""}>
                      <Link to='/Masterconfiguration'>
                        <i class="bi bi-circle"></i><span> Master Configuration </span>
                      </Link>
                    </li>
                    {Api.CheckView(30) == 1 ?
                      <li class={Employent ? "list" : ""}>
                        <a
                          class="nav-link " href='/Employmenttypetable' state={{ ID: 0, Flag: 'ET' }}>
                          <i class="bi bi-grid"></i>
                          <span>Employment Type</span>
                        </a>
                      </li>
                      : null}
                  </ul>
                </ul>
              </li>

              : null}

            {/* {(Api.CheckView(19) == 1) ?
              <li class="nav-item">
                <a class={clickCommisionshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickCommisionshow(!clickCommisionshow)} data-bs-toggle="collapse" >

                  <i class="bi bi-cash-coin"></i><span>Commission Creation</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickCommisionshow || Declarecommission) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    {Api.CheckView(19) == 1 ?
                      <li class={Declarecommission ? "list" : ""}>
                        <Link to='/DeclareCommision' state={{ ID: 0, Flag: 'DC' }}>
                          <i class="bi bi-circle"></i><span>Declare Commission</span>
                        </Link>
                      </li>
                      : null}

                  </ul>
                </ul>
              </li>
              : null} */}



            {(Api.CheckView(20) == 1 || Api.CheckView(21) == 1 || Api.CheckView(22) == 1 || Api.CheckView(23) == 1 || Api.CheckView(24) == 1) ?
              <li class="nav-item">
                <a class={clickReportshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickReportshow(!clickReportshow)} data-bs-toggle="collapse" >
                  <i class="bi bi-file-earmark-pdf"></i><span>Reports</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={clickReportshow || Agentcommission || Branchperformance || Employeeperformance || Agentbrokerperformance || EmploeePaymentReport || OnlinePaymentReport ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>

                    {/* {Api.CheckView(20) == 1 ?

                      <li class={Agentcommission ? "list" : ""}>
                        <Link to='/agentcommisiontable'>
                          <i class="bi bi-circle"></i><span>Agent Commision </span>
                        </Link>
                      </li>
                      : null} */}

                    {/* {Api.CheckView(21) == 1 ?
                      <li class={Agentbrokerperformance ? "list" : ""}>
                        <Link to='/agentbrokerperformancetable'>
                          <i class="bi bi-circle"></i><span> Agent Broker Performance   </span>
                        </Link>
                      </li>
                      : null} */}
                    {Api.CheckView(22) == 1 ?
                      <li>
                        <Link to='/OfficeCashReport'>
                          <i class="bi bi-circle"></i><span> Cash Payment Report </span>
                        </Link>
                      </li>
                      : null}
                    {Api.CheckView(23) == 1 ?
                      <li>
                        <Link to='/TotalPayments'>
                          <i class="bi bi-circle"></i><span>Total Payment Lists </span>
                        </Link>
                      </li>
                      : null}
                    {sessionStorage.getItem('AccessType') == 'Agent' ?
                      <li>
                        <Link to='/TotalAgentPayments'>
                          <i class="bi bi-circle"></i><span>Total Payment Lists </span>
                        </Link>
                      </li>
                      : null}
                    {/* {Api.CheckView(41) == 1 ?
                      <li class={EmploeePaymentReport ? "list" : ""}>
                        <Link to='/EmploeePaymentReport'>
                          <i class="bi bi-circle"></i><span> Cash Payment Report </span>
                        </Link>
                      </li>
                      : null} */}
                    {Api.CheckView(24) == 1 ?
                      <li class={OnlinePaymentReport ? "list" : ""}>
                        <Link to='/OnlinePaymentReport'>
                          <i class="bi bi-circle"></i><span> Online Payment Report </span>
                        </Link>
                      </li>
                      : null}
                    {/* {sessionStorage.getItem('AccessType') == 'User' ?
                    <li class="nav-item">
                      <a

                        class="nav-link " href='/Data_table' state={{ ID: 0, Flag: 'RI' }}>
                        <i class="bi bi-grid"></i>
                        <span>Data_table</span>
                      </a>
                    </li>
                    :null} */}
                  </ul>
                </ul>
              </li>
              : null}


            <li class="nav-item">
              <Link to={'/Customersupporttable'}
                class="nav-link " state={{ ID: 0, Flag: 'CS' }}>
                <i class="bi bi-telephone-x"></i>
                <span>Customer Support</span>
              </Link>
            </li>

            {/* <li class="nav-item">
              <Link to={'/Customersupportgrid'}
                class="nav-link " state={{ ID: 0, Flag: 'CSG' }}>
                <i class="bi bi-telephone-x"></i>
                <span>Customer Support Grid</span>
              </Link>
            </li> */}


            {/* {sessionStorage.getItem('AccessType')=='User'? */}
            {Api.CheckView(31) == 1 ?
              <li class="nav-item">
                <a
                  class="nav-link " href='/Postjobstable' state={{ ID: 0, Flag: 'JD' }}>
                  <i class="bi bi-grid"></i>
                  <span>Post Jobs</span>
                </a>
              </li>
              : null}
            {/* {Api.CheckView(32) == 1 ?
              <li class="nav-item">
                <a

                  class="nav-link " href='/reinvoice_grid' state={{ ID: 0, Flag: 'RI' }}>
                  <i class="bi bi-grid"></i>
                  <span>Reinvoice</span>
                </a>
              </li>
              : null} */}
            {sessionStorage.getItem('AccessType') == 'User' ?
              <li class="nav-item">
                <a class={clickCommisionshow ? "nav-link" : "nav-link collapsed"} data-bs-target="#components-nav" onClick={() => onclickCommisionshow(!clickCommisionshow)} data-bs-toggle="collapse" >

                  <i class="bi bi-cash-coin"></i><span>Back Office</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class={(clickCommisionshow || BoJobseekerApproval || BoEmployerApproval) ? "nav-content collapse show" : "nav-content collapse"} data-bs-parent="#sidebar-nav">
                  <ul>
                    <li class={BoEmployerApproval ? "list" : ""}>
                      <Link to='/BoEmployerApproval' state={{ ID: 0, Flag: 'DC' }}>
                        <i class="bi bi-circle"></i><span>Employer Approval</span>
                      </Link>
                    </li>
                    <li class={BoJobseekerApproval ? "list" : ""}>
                      <Link to='/BoJobseekerApproval' state={{ ID: 0, Flag: 'DC' }}>
                        <i class="bi bi-circle"></i><span>Jobseeker Approvaal</span>
                      </Link>
                    </li>

                  </ul>
                </ul>
              </li>
              : null}


          </ul>

        </div>


        {/* <!-- End Sidebar--> */}



      </body>

    </>

  )
}

export default Base